export function getRedirectUri(): string {
  const protocol = window.location.protocol;
  const hostname = window.location.hostname;
  const port = window.location.port;
  
  // Construct the base URL with protocol and hostname
  let baseUrl = `${protocol}//${hostname}`;
  
  // Add port if it exists and isn't the default port for the protocol
  if (port && 
      ((protocol === 'http:' && port !== '80') || 
       (protocol === 'https:' && port !== '443'))) {
    baseUrl += `:${port}`;
  }
  
  return `${baseUrl}/oauth/callback`;
}