import React from 'react';
import { MessageList } from './MessageList';
import { MessageInput } from './MessageInput';
import { useMastodonStore } from '../../store/mastodonStore';

export function ChatWindow() {
  const activeConversation = useMastodonStore((state) => state.activeConversation);

  if (!activeConversation) {
    return (
      <div className="flex-1 flex items-center justify-center text-gray-500 dark:text-gray-400">
        Select a conversation to start messaging
      </div>
    );
  }

  return (
    <div className="flex-1 flex flex-col">
      <MessageList />
      <MessageInput />
    </div>
  );
}