import React from 'react';
import { User } from 'lucide-react';
import { Conversation } from '../../types/mastodon';
import { formatRelativeTime } from '../../utils/date/formatting';
import { cleanMessageContent } from '../../utils/message/formatting';

interface ConversationItemProps {
  conversation: Conversation;
  isActive: boolean;
  onClick: () => void;
}

export function ConversationItem({ conversation, isActive, onClick }: ConversationItemProps) {
  const participant = conversation.participants[0];

  return (
    <button
      onClick={onClick}
      className={`w-full p-4 text-left hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors ${
        isActive ? 'bg-blue-50 dark:bg-blue-900/20' : ''
      }`}
    >
      <div className="flex items-center space-x-3">
        {participant?.avatar ? (
          <img
            src={participant.avatar}
            alt={participant.displayName}
            className="w-10 h-10 rounded-full"
          />
        ) : (
          <div className="w-10 h-10 rounded-full bg-gray-200 dark:bg-gray-700 flex items-center justify-center">
            <User className="w-6 h-6 text-gray-500 dark:text-gray-400" />
          </div>
        )}
        <div className="flex-1 min-w-0">
          <p className="text-sm font-medium text-gray-900 dark:text-white truncate">
            {participant?.displayName}
          </p>
          {conversation.lastMessage && (
            <>
              <p className="text-sm text-gray-500 dark:text-gray-400 truncate">
                {cleanMessageContent(conversation.lastMessage.content)}
              </p>
              <p className="text-xs text-gray-400 dark:text-gray-500 mt-1">
                {formatRelativeTime(conversation.lastMessage.timestamp)}
              </p>
            </>
          )}
        </div>
        {conversation.unread && (
          <div className="w-2 h-2 bg-blue-600 dark:bg-blue-400 rounded-full"></div>
        )}
      </div>
    </button>
  );
}