import React, { useState, useRef, useEffect } from 'react';
import { Settings, LogOut, User, Laptop } from 'lucide-react';
import { useMastodonStore } from '../../store/mastodonStore';
import { ThemeToggle } from '../theme/ThemeToggle';
import { revokeToken } from '../../services/api/authApi';
import { useSessionManagement } from '../../hooks/useSessionManagement';
import { SessionsModal } from './SessionsModal';

export function UserDropdown() {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [showSessions, setShowSessions] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const instance = useMastodonStore((state) => state.instance);
  const currentUser = useMastodonStore((state) => state.currentUser);
  const clearStore = useMastodonStore((state) => state.clearStore);
  const { sessions, currentSessionId, loadSessions, revokeSession } = useSessionManagement();

  useEffect(() => {
    if (showSessions && currentUser && instance) {
      loadSessions(currentUser.id, instance.domain);
    }
  }, [showSessions, currentUser, instance, loadSessions]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleLogout = async () => {
    if (!instance || isLoggingOut) return;
    
    setIsLoggingOut(true);
    try {
      await clearStore();
    } catch (error) {
      console.error('Error during logout:', error);
    } finally {
      setIsLoggingOut(false);
    }
  };

  if (!currentUser || !instance) return null;

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-2 p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
      >
        {currentUser.avatar ? (
          <img
            src={currentUser.avatar}
            alt={currentUser.displayName}
            className="w-8 h-8 rounded-full"
          />
        ) : (
          <User className="w-8 h-8 p-1 bg-gray-100 dark:bg-gray-700 rounded-full text-gray-600 dark:text-gray-300" />
        )}
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-64 bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700 py-1 z-50">
          <div className="px-4 py-2 border-b border-gray-200 dark:border-gray-700">
            <p className="font-medium text-gray-900 dark:text-white">{currentUser.displayName}</p>
            <p className="text-xs text-gray-400 dark:text-gray-500 mt-1">{instance.domain}</p>
          </div>
          
          <button
            onClick={() => {
              setShowSessions(true);
              setIsOpen(false);
            }}
            className="w-full px-4 py-2 text-left text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 flex items-center space-x-2"
          >
            <Laptop className="w-4 h-4" />
            <span>Manage Sessions</span>
          </button>
          
          <button
            onClick={() => window.open(`https://${instance.domain}/@${currentUser.username}`, '_blank')}
            className="w-full px-4 py-2 text-left text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 flex items-center space-x-2"
          >
            <Settings className="w-4 h-4" />
            <span>Profile Settings</span>
          </button>
          
          <div className="px-4 py-2 border-t border-gray-200 dark:border-gray-700">
            <ThemeToggle />
          </div>
          
          <button
            onClick={handleLogout}
            disabled={isLoggingOut}
            className="w-full px-4 py-2 text-left text-sm text-red-600 dark:text-red-400 hover:bg-red-50 dark:hover:bg-red-900/20 flex items-center space-x-2"
          >
            <LogOut className="w-4 h-4" />
            <span>{isLoggingOut ? 'Logging out...' : 'Log Out'}</span>
          </button>
        </div>
      )}
      
      {showSessions && (
        <SessionsModal
          sessions={sessions}
          currentSessionId={currentSessionId}
          onClose={() => setShowSessions(false)}
          onRevokeSession={revokeSession}
        />
      )}
    </div>
  );
}