import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useMastodonStore } from '../../store/mastodonStore';
import { getUserByHandle } from '../../services/api/userApi';

export function UserRedirect() {
  const { handle } = useParams<{ handle: string }>();
  const navigate = useNavigate();
  const instance = useMastodonStore((state) => state.instance);

  useEffect(() => {
    if (!handle) {
      navigate('/');
      return;
    }

    if (!instance) {
      // Store the handle to redirect after login
      sessionStorage.setItem('redirect_handle', handle);
      navigate('/');
      return;
    }

    const initializeChat = async () => {
      try {
        const user = await getUserByHandle(instance.domain, instance.accessToken, handle);
        if (user) {
          // TODO: Initialize chat with user
          navigate('/');
        } else {
          navigate('/');
        }
      } catch (error) {
        console.error('Error looking up user:', error);
        navigate('/');
      }
    };

    initializeChat();
  }, [handle, instance, navigate]);

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 flex items-center justify-center">
      <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500 mx-auto"></div>
        <p className="mt-4 text-gray-600 dark:text-gray-300">Looking up user...</p>
      </div>
    </div>
  );
}