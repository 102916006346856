import React from 'react';
import { Monitor, Smartphone, Laptop, X } from 'lucide-react';
import { formatRelativeTime } from '../../utils/date/formatting';
import type { UserSession } from '../../types/mastodon';

interface SessionsModalProps {
  sessions: UserSession[];
  currentSessionId: string;
  onClose: () => void;
  onRevokeSession: (sessionId: string) => void;
}

export function SessionsModal({ sessions, currentSessionId, onClose, onRevokeSession }: SessionsModalProps) {
  const getDeviceIcon = (platform: string) => {
    if (platform.includes('Mobile') || platform.includes('iPhone') || platform.includes('Android')) {
      return <Smartphone className="w-5 h-5" />;
    }
    if (platform.includes('Mac') || platform.includes('Win')) {
      return <Laptop className="w-5 h-5" />;
    }
    return <Monitor className="w-5 h-5" />;
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-lg w-full max-h-[80vh] flex flex-col">
        <div className="p-4 border-b border-gray-200 dark:border-gray-700 flex items-center justify-between">
          <h2 className="text-lg font-semibold dark:text-white">Active Sessions</h2>
          <button
            onClick={onClose}
            className="p-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
          >
            <X className="w-5 h-5" />
          </button>
        </div>
        
        <div className="overflow-y-auto flex-1 p-4">
          <div className="space-y-4">
            {sessions.map((session) => (
              <div
                key={session.session_id}
                className="flex items-start justify-between p-4 bg-gray-50 dark:bg-gray-700/50 rounded-lg"
              >
                <div className="flex items-start space-x-3">
                  <div className="text-gray-500 dark:text-gray-400">
                    {getDeviceIcon(session.device_info.platform)}
                  </div>
                  <div>
                    <div className="font-medium dark:text-white">
                      {session.device_info.platform}
                      {session.session_id === currentSessionId && (
                        <span className="ml-2 text-xs bg-blue-100 dark:bg-blue-900 text-blue-600 dark:text-blue-400 px-2 py-0.5 rounded-full">
                          Current
                        </span>
                      )}
                    </div>
                    <div className="text-sm text-gray-500 dark:text-gray-400">
                      {session.device_info.browser}
                    </div>
                    <div className="text-xs text-gray-400 dark:text-gray-500 mt-1">
                      Last active {formatRelativeTime(new Date(session.last_active))}
                    </div>
                  </div>
                </div>
                
                {session.session_id !== currentSessionId && (
                  <button
                    onClick={() => onRevokeSession(session.session_id)}
                    className="text-sm text-red-600 dark:text-red-400 hover:text-red-700 dark:hover:text-red-300"
                  >
                    Revoke
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}