import { nanoid } from 'nanoid';
import { supabase } from '../client';
import type { UserInfo } from '../../../types/mastodon';

interface DeviceInfo {
  name: string;
  platform: string;
  browser: string;
  lastIp: string;
}

function getDeviceInfo(): DeviceInfo {
  const ua = navigator.userAgent;
  const platform = navigator.platform;
  
  return {
    name: platform,
    platform: platform,
    browser: ua,
    lastIp: '' // IP will be logged by Supabase
  };
}

export async function storeSession(userInfo: UserInfo, domain: string, accessToken: string) {
  const clientId = localStorage.getItem('client_id');
  const clientSecret = localStorage.getItem('client_secret');

  if (!clientId || !clientSecret) {
    throw new Error('Missing OAuth credentials');
  }

  const { data, error } = await supabase
    .from('user_sessions')
    .upsert({
      session_id: nanoid(),
      user_id: userInfo.id,
      domain,
      access_token: accessToken,
      client_id: clientId,
      client_secret: clientSecret,
      user_info: userInfo,
      last_active: new Date().toISOString(),
      device_info: getDeviceInfo()
    }, {
      onConflict: 'session_id',
      ignoreDuplicates: false
    })
    .select()
    .single();

  if (error) throw error;
  localStorage.setItem('session_id', data.session_id);
  return data;
}

export async function getSession(sessionId: string) {
  const { data, error } = await supabase
    .from('user_sessions')
    .select()
    .eq('session_id', sessionId)
    .single();

  if (error) throw error;
  return data;
}

export async function clearSession(sessionId: string, revokeToken = true) {
  const { data: session, error: fetchError } = await supabase
    .from('user_sessions')
    .select('access_token, domain, client_id, client_secret')
    .eq('session_id', sessionId)
    .single();

  if (fetchError) throw fetchError;

  if (revokeToken && session) {
    try {
      await revokeAccessToken(
        session.domain,
        session.access_token,
        session.client_id,
        session.client_secret
      );
    } catch (error) {
      console.error('Error revoking token:', error);
    }
  }

  const { error } = await supabase
    .from('user_sessions')
    .delete()
    .eq('session_id', sessionId);

  if (error) throw error;
  localStorage.removeItem('session_id');
}

export async function listSessions(userId: string, domain: string) {
  const { data, error } = await supabase
    .from('user_sessions')
    .select()
    .eq('user_id', userId)
    .eq('domain', domain)
    .order('last_active', { ascending: false });

  if (error) throw error;
  return data || [];
}