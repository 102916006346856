import { useState } from 'react';
import { useMastodonStore } from '../store/mastodonStore';
import { sendDirectMessage } from '../services/mastodonApi';

export function useSendMessage() {
  const [isLoading, setIsLoading] = useState(false);
  const instance = useMastodonStore((state) => state.instance);
  const activeConversation = useMastodonStore((state) => state.activeConversation);
  const addMessage = useMastodonStore((state) => state.addMessage);

  const sendMessage = async (content: string) => {
    if (!instance || !activeConversation || !content.trim()) return;

    setIsLoading(true);
    try {
      const response = await sendDirectMessage(
        instance.domain,
        instance.accessToken,
        activeConversation,
        content
      );

      addMessage({
        id: response.id,
        content: content,
        sender: 'self',
        timestamp: new Date(),
        conversationId: activeConversation
      });

      return true;
    } catch (error) {
      console.error('Error sending message:', error);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return { sendMessage, isLoading };
}