import { useState, useCallback } from 'react';
import { listSessions, clearSession } from '../services/supabase/auth/session';
import type { UserSession } from '../types/mastodon';

export function useSessionManagement() {
  const [sessions, setSessions] = useState<UserSession[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const currentSessionId = localStorage.getItem('session_id') || '';

  const loadSessions = useCallback(async (userId: string, domain: string) => {
    setIsLoading(true);
    try {
      const sessions = await listSessions(userId, domain);
      setSessions(sessions);
    } catch (error) {
      console.error('Error loading sessions:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const revokeSession = useCallback(async (sessionId: string) => {
    try {
      await clearSession(sessionId);
      setSessions(prev => prev.filter(s => s.session_id !== sessionId));
    } catch (error) {
      console.error('Error revoking session:', error);
    }
  }, []);

  return {
    sessions,
    currentSessionId,
    isLoading,
    loadSessions,
    revokeSession
  };
}