import { supabase } from './client';

export async function initializeAuth(userId: string) {
  const { data: { session }, error } = await supabase.auth.getSession();
  if (error) throw error;
  
  if (!session) {
    throw new Error('No active session');
  }
  
  return session;
}

export async function cleanupAuth() {
  const { error } = await supabase.auth.signOut();
  if (error) throw error;
}

export async function getUser() {
  const { data: { user }, error } = await supabase.auth.getUser();
  if (error) throw error;
  return user;
}