interface PaginationLinks {
  next?: string;
  prev?: string;
  maxId?: string;
  minId?: string;
}

export function parseLinkHeader(linkHeader?: string): PaginationLinks {
  if (!linkHeader) return {};

  const links: PaginationLinks = {};
  
  // Split parts by comma
  const parts = linkHeader.split(',');
  
  parts.forEach(part => {
    const section = part.split(';');
    if (section.length !== 2) return;

    // Get URL and rel values
    const url = section[0].trim().slice(1, -1);
    const rel = section[1].trim().slice(5, -1);

    // Parse URL parameters
    const urlParams = new URL(url).searchParams;
    
    if (rel === 'next') {
      links.next = url;
      links.maxId = urlParams.get('max_id') || undefined;
    } else if (rel === 'prev') {
      links.prev = url;
      links.minId = urlParams.get('min_id') || undefined;
    }
  });

  return links;
}