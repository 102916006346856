import axios from 'axios';
import { Conversation } from '../../types/mastodon';
import { parseLinkHeader } from '../../utils/apiUtils';
import { cleanMessageContent } from '../../utils/messageUtils';

export async function fetchConversations(
  domain: string, 
  accessToken: string,
  params: { maxId?: string; sinceId?: string; minId?: string; limit?: number } = {}
): Promise<{ conversations: Conversation[]; pagination: any }> {
  try {
    const response = await axios.get(
      `https://${domain}/api/v1/conversations`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
        params: {
          max_id: params.maxId,
          since_id: params.sinceId,
          min_id: params.minId,
          limit: params.limit || 20
        }
      }
    );

    const pagination = parseLinkHeader(response.headers.link);
    
    const conversations = response.data.map((conv: any) => ({
      id: conv.id,
      unread: conv.unread,
      participants: conv.accounts.map((account: any) => ({
        id: account.id,
        username: account.username,
        acct: account.acct,
        displayName: account.display_name || account.username,
        avatar: account.avatar
      })),
      lastMessage: conv.last_status ? {
        id: conv.last_status.id,
        content: cleanMessageContent(conv.last_status.content),
        sender: conv.last_status.account.acct,
        timestamp: new Date(conv.last_status.created_at),
        conversationId: conv.id,
        senderInfo: {
          id: conv.last_status.account.id,
          username: conv.last_status.account.username,
          displayName: conv.last_status.account.display_name || conv.last_status.account.username,
          avatar: conv.last_status.account.avatar,
          acct: conv.last_status.account.acct
        }
      } : null
    }));

    return { conversations, pagination };
  } catch (error) {
    console.error('Error fetching conversations:', error);
    throw error;
  }
}

export async function markConversationAsRead(
  domain: string,
  accessToken: string,
  conversationId: string
): Promise<void> {
  try {
    await axios.post(
      `https://${domain}/api/v1/conversations/${conversationId}/read`,
      {},
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
  } catch (error) {
    console.error('Error marking conversation as read:', error);
    throw error;
  }
}