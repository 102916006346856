import { useEffect, useCallback, useState } from 'react';
import { useMastodonStore } from '../store/mastodonStore';
import { syncMessages } from '../services/supabase/messages/sync';
import { fetchMessages } from '../services/supabase/messages/fetch';
import { markConversationAsRead } from '../services/api/conversationApi';
import { subscribeToMessages } from '../services/supabase/messages/subscription';

export function useMessages() {
  const instance = useMastodonStore((state) => state.instance);
  const activeConversation = useMastodonStore((state) => state.activeConversation);
  const setMessages = useMastodonStore((state) => state.setMessages);
  const [isLoading, setIsLoading] = useState(true);
  const [isSyncing, setIsSyncing] = useState(false);

  const loadMessages = useCallback(async () => {
    if (!instance || !activeConversation) return;

    try {
      setIsSyncing(true);
      // Sync messages from Mastodon to Supabase and get them back
      const messages = await syncMessages(
        instance.domain,
        instance.accessToken,
        activeConversation
      );
      
      setMessages(activeConversation, messages);
      
      // Mark conversation as read when messages are loaded
      await markConversationAsRead(instance.domain, instance.accessToken, activeConversation);
    } catch (error) {
      console.error('Error loading messages:', error);
    } finally {
      setIsLoading(false);
      setIsSyncing(false);
    }
  }, [instance, activeConversation, setMessages]);

  // Set up real-time subscription
  useEffect(() => {
    if (!instance || !activeConversation) return;

    const subscription = subscribeToMessages(
      activeConversation,
      instance.domain,
      (message) => {
        setMessages(activeConversation, prev => [...prev, message]);
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [instance, activeConversation, setMessages]);

  // Initial load
  useEffect(() => {
    if (activeConversation) {
      setIsLoading(true);
      loadMessages();
    }
  }, [activeConversation, loadMessages]);

  return { isLoading, isSyncing, loadMessages };
}