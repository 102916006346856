import { supabase } from '../client';
import type { Message } from '../../../types/mastodon';
import { fetchConversationMessages } from '../../api/mastodon/messages.ts';
import { storeMessage } from './store';

export async function syncMessages(domain: string, accessToken: string, conversationId: string): Promise<Message[]> {
  // First fetch messages from Mastodon API
  const messages = await fetchConversationMessages(domain, accessToken, conversationId);
  
  // Store all messages in a single transaction
  const { error } = await supabase.rpc('store_messages_batch', {
    messages_json: JSON.stringify(messages.map(msg => ({
      id: msg.id,
      content: msg.content,
      sender: msg.sender,
      timestamp: msg.timestamp.toISOString(),
      conversation_id: msg.conversationId,
      instance_domain: domain,
      sender_info: msg.senderInfo
    })))
  });

  if (error) {
    console.error('Error storing messages:', error);
    throw error;
  }
  
  return messages;
}

export async function getThreadedMessages(conversationId: string, instanceDomain: string): Promise<Message[]> {
  const { data, error } = await supabase
    .from('messages')
    .select()
    .eq('conversation_id', conversationId)
    .eq('instance_domain', instanceDomain)
    .order('timestamp', { ascending: true });

  if (error) throw error;
  if (!data) return [];

  return data.map(msg => ({
    id: msg.id,
    content: msg.content,
    sender: msg.sender,
    timestamp: new Date(msg.timestamp),
    conversationId: msg.conversation_id,
    senderInfo: msg.sender_info
  }));
}