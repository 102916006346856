import React from 'react';
import { useTheme } from '../../hooks/useTheme';
import { ThemeToggle } from '../theme/ThemeToggle';

interface ThemeProviderProps {
  children: React.ReactNode;
}

export function ThemeProvider({ children }: ThemeProviderProps) {
  useTheme(); // Initialize theme
  return <>{children}</>;
}