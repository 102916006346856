import { useEffect, useCallback, useState } from 'react';
import { useMastodonStore } from '../store/mastodonStore';
import { fetchConversations } from '../services/api/conversationApi';
import { subscribeToConversations } from '../services/supabase/conversations/subscription';

export function useConversations() {
  const instance = useMastodonStore((state) => state.instance);
  const setConversations = useMastodonStore((state) => state.setConversations);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState<{
    maxId?: string;
    minId?: string;
  }>({});

  useEffect(() => {
    if (!instance) return;

    const subscription = subscribeToConversations(
      instance.domain,
      (conversation) => {
        setConversations(prev => 
          prev.map(c => c.id === conversation.id ? conversation : c)
        );
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [instance, setConversations]);
  const loadConversations = useCallback(async (params?: { maxId?: string; minId?: string }) => {
    if (!instance) return;

    setIsLoading(true);
    try {
      const result = await fetchConversations(instance.domain, instance.accessToken, {
        ...params,
        limit: 20
      });
      
      setConversations(result.conversations);
      setPagination({
        maxId: result.pagination.maxId,
        minId: result.pagination.minId
      });
    } catch (error) {
      console.error('Error loading conversations:', error);
    } finally {
      setIsLoading(false);
    }
  }, [instance, setConversations]);

  const loadMore = useCallback(() => {
    if (pagination.maxId) {
      loadConversations({ maxId: pagination.maxId });
    }
  }, [pagination.maxId, loadConversations]);

  const refresh = useCallback(() => {
    if (pagination.minId) {
      loadConversations({ minId: pagination.minId });
    } else {
      loadConversations();
    }
  }, [pagination.minId, loadConversations]);

  useEffect(() => {
    loadConversations();
    const interval = setInterval(refresh, 30000); // Poll every 30 seconds
    return () => clearInterval(interval);
  }, [loadConversations, refresh]);

  return {
    isLoading,
    loadMore,
    refresh,
    hasMore: !!pagination.maxId
  };
}