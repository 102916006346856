import React from 'react';
import { UserDropdown } from '../user/UserDropdown';
import { useMastodonStore } from '../../store/mastodonStore';

export function Header() {
  const instance = useMastodonStore((state) => state.instance);
  const currentUser = useMastodonStore((state) => state.currentUser);
  const activeConversation = useMastodonStore((state) => state.activeConversation);
  const conversations = useMastodonStore((state) => state.conversations);
  
  const currentConversation = conversations.find(c => c.id === activeConversation);

  return (
    <div className="flex items-center justify-between p-4 border-b border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800">
      <div className="flex-1 min-w-0">
        <h1 className="text-lg font-semibold text-gray-900 dark:text-white truncate">
          {currentConversation 
            ? currentConversation.participants.map(p => p.displayName).join(', ')
            : 'Messagedon'}
        </h1>
        <p className="text-sm text-gray-500 dark:text-gray-400 truncate">
          {instance?.domain}
        </p>
      </div>
      <UserDropdown />
    </div>
  );
}