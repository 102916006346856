export function ensureDate(date: Date | string | null | undefined): Date {
  if (!date) return new Date();
  
  if (date instanceof Date) {
    return date;
  }
  
  try {
    return new Date(date);
  } catch {
    return new Date();
  }
}