import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMastodonStore } from '../../store/mastodonStore';
import { getAccessToken, verifyCredentials } from '../../services/api/mastodon/auth';
import { storeSession } from '../../services/supabase/auth/session';

export function OAuthCallback() {
  const navigate = useNavigate();
  const setInstance = useMastodonStore((state) => state.setInstance);
  const setCurrentUser = useMastodonStore((state) => state.setCurrentUser);
  const [error, setError] = useState<string | null>(null);
  const [status, setStatus] = useState<string>('Initializing your session...');

  useEffect(() => {
    const handleCallback = async () => {
      const params = new URLSearchParams(window.location.search);
      const code = params.get('code');
      const state = params.get('state');
      const storedState = sessionStorage.getItem('oauth_state');
      const domain = sessionStorage.getItem('mastodon_domain');
      const clientId = localStorage.getItem('client_id');
      const clientSecret = localStorage.getItem('client_secret');

      if (!code || !state || !storedState || state !== storedState || !domain || !clientId || !clientSecret) {
        setError('Authentication failed: Invalid or missing parameters');
        navigate('/');
        return;
      }

      try {
        setStatus(`Connecting to ${domain}`);
        const accessToken = await getAccessToken(domain, code, clientId, clientSecret);
        
        setStatus('Verifying your credentials');
        const userInfo = await verifyCredentials(domain, accessToken);
        
        setStatus('Finalizing your session');
        await storeSession(userInfo, domain, accessToken);

        setInstance({ domain, accessToken });
        setCurrentUser(userInfo);
        
        // Clean up session storage
        sessionStorage.removeItem('oauth_state');
        sessionStorage.removeItem('mastodon_domain');
        sessionStorage.removeItem('redirect_handle');
        
        const redirectHandle = sessionStorage.getItem('redirect_handle');
        navigate(redirectHandle ? `/t/${redirectHandle}` : '/');
      } catch (error) {
        console.error('OAuth error:', error);
        setError('Authentication failed. Please try logging in again.');
        navigate('/');
      }
    };

    handleCallback();
  }, [navigate, setInstance, setCurrentUser]);

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 flex items-center justify-center p-4">
      <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg text-center max-w-md w-full">
        <div className="flex flex-col items-center space-y-4">
        {error ? (
          <>
            <div className="text-red-500 dark:text-red-400 space-y-2">
              <div className="font-semibold text-lg">Authentication Failed</div>
              <p className="text-sm">{error}</p>
            </div>
            <button
              onClick={() => navigate('/')}
              className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
            >
              Return to Login
            </button>
          </>
        ) : (
          <>
            <div className="animate-spin rounded-full h-12 w-12 border-4 border-blue-500 border-b-transparent" />
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
              Welcome to Messagedon
            </h2>
            <div className="flex items-center space-x-2 text-gray-600 dark:text-gray-300">
              <span className="inline-block w-2 h-2 bg-blue-500 rounded-full animate-pulse" />
              {status}
            </div>
          </>
        )}
        </div>
      </div>
    </div>
  );
}