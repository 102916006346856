import React, { useEffect, useRef } from 'react';
import { useMastodonStore } from '../../store/mastodonStore';
import { useMessages } from '../../hooks/useMessages';
import { MessageGroup } from './MessageGroup';
import { LoadingSpinner } from '../common/LoadingSpinner';
import { useMessageGroups } from '../../hooks/useMessageGroups';

export function MessageList() {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const { isLoading, isSyncing } = useMessages();
  const messages = useMastodonStore((state) => {
    const activeConversation = state.activeConversation;
    return activeConversation ? state.messages[activeConversation] || [] : [];
  });

  const messageGroups = useMessageGroups(messages);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  if (isLoading && messages.length === 0) {
    return (
      <div className="flex-1 flex items-center justify-center">
        <div className="text-center">
          <LoadingSpinner size="lg" className="mx-auto mb-4" />
          <p className="text-gray-500 dark:text-gray-400">
            {isSyncing ? 'Syncing messages...' : 'Loading messages...'}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex-1 overflow-y-auto p-4 space-y-4 bg-gray-50 dark:bg-gray-900">
      {messageGroups.map((group) => (
        <MessageGroup 
          key={group.date.toISOString()} 
          date={group.date}
          messages={group.messages}
        />
      ))}
      <div ref={messagesEndRef} />
    </div>
  );
}