import { decode } from 'he';

export function cleanMessageContent(content: string): string {
  // Remove HTML tags
  let cleanContent = content.replace(/<[^>]*>/g, '');
  
  // Decode HTML entities
  cleanContent = decode(cleanContent);
  
  // Remove mentions
  cleanContent = cleanContent.replace(/@[\w\-]+(@[\w\-\.]+)?/g, '').trim();
  
  // Normalize whitespace
  return cleanContent.replace(/\s+/g, ' ').trim();
}