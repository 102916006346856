import type { MastodonToken } from './types';

export async function getAccessToken(
  domain: string,
  code: string,
  clientId: string,
  clientSecret: string
): Promise<string> {
  const redirectUri = `${window.location.origin}/oauth/callback`;

  const response = await fetch(`https://${domain}/oauth/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      client_id: clientId,
      client_secret: clientSecret,
      redirect_uri: redirectUri,
      grant_type: 'authorization_code',
      code: code,
      scope: 'read write follow'
    }),
  });

  if (!response.ok) {
    const error = await response.text();
    throw new Error(`Failed to get access token: ${error}`);
  }

  const data: MastodonToken = await response.json();
  return data.access_token;
}

export async function verifyCredentials(domain: string, accessToken: string) {
  const response = await fetch(
    `https://${domain}/api/v1/accounts/verify_credentials`,
    {
      headers: { Authorization: `Bearer ${accessToken}` }
    }
  );

  if (!response.ok) {
    const error = await response.text();
    throw new Error(`Failed to verify credentials: ${error}`);
  }

  const data = await response.json();
  return {
    id: data.id,
    username: data.username,
    displayName: data.display_name || data.username,
    avatar: data.avatar,
    acct: data.acct
  };
}