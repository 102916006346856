import React, { useState } from 'react';
import { Header } from './Header';
import { ConversationList } from '../conversations/ConversationList';
import { ChatWindow } from '../chat/ChatWindow';
import { MenuButton } from './MenuButton';
import { useTheme } from '../../hooks/useTheme';
import { useWindowSize } from '../../hooks/useWindowSize';

export function ChatLayout() {
  const [showSidebar, setShowSidebar] = useState(false);
  const { width } = useWindowSize();
  useTheme();

  React.useEffect(() => {
    if (width >= 1024) {
      setShowSidebar(false);
    }
  }, [width]);

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 transition-colors duration-200">
      <div className="container mx-auto h-screen max-w-6xl p-4">
        <div className="h-full flex bg-white dark:bg-gray-800 shadow-xl rounded-lg overflow-hidden relative">
          {/* Menu button - only show when sidebar is hidden on mobile */}
          {!showSidebar && width < 1024 && (
            <div className="absolute left-4 top-4 z-50">
              <MenuButton onClick={() => setShowSidebar(true)} />
            </div>
          )}

          {/* Sidebar */}
          <div
            className={`
              fixed lg:relative inset-y-0 left-0 w-80 
              bg-white dark:bg-gray-800 
              transform transition-transform duration-200 ease-in-out 
              lg:translate-x-0 z-40
              ${showSidebar ? 'translate-x-0' : '-translate-x-full'}
            `}
          >
            <ConversationList onConversationSelect={() => setShowSidebar(false)} />
          </div>

          {/* Overlay */}
          {showSidebar && width < 1024 && (
            <div
              className="fixed inset-0 bg-black bg-opacity-50 lg:hidden z-30"
              onClick={() => setShowSidebar(false)}
            />
          )}

          {/* Main content */}
          <div className="flex-1 flex flex-col min-w-0">
            <Header />
            <ChatWindow />
          </div>
        </div>
      </div>
    </div>
  );
}