import React from 'react';
import { Menu } from 'lucide-react';
import clsx from 'clsx';

interface MenuButtonProps {
  onClick: () => void;
  className?: string;
}

export function MenuButton({ onClick, className }: MenuButtonProps) {
  return (
    <button
      onClick={onClick}
      className={clsx(
        'p-2 text-gray-600 dark:text-gray-300',
        'hover:bg-gray-100 dark:hover:bg-gray-700',
        'rounded-lg transition-colors duration-200',
        'focus:outline-none focus:ring-2 focus:ring-blue-500',
        className
      )}
      aria-label="Toggle menu"
    >
      <Menu className="w-6 h-6" />
    </button>
  );
}