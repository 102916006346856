import React, { useState, useEffect, useRef } from 'react';
import { Globe } from 'lucide-react';
import { createMastodonApp } from '../../services/api/userApi';
import { getRedirectUri } from '../../utils/urlUtils';
import { LoadingSpinner } from '../common/LoadingSpinner';
import { ThemeToggle } from '../theme/ThemeToggle';
import { useTheme } from '../../hooks/useTheme';

export function LoginForm() {
  const [domain, setDomain] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const { isDark } = useTheme();

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const cleanDomain = domain.replace(/^https?:\/\//, '').replace(/\/$/, '');
      
      // First, verify the domain is reachable
      try {
        await fetch(`https://${cleanDomain}/api/v1/instance`);
      } catch (err) {
        throw new Error('Unable to connect to Mastodon instance');
      }

      const app = await createMastodonApp(cleanDomain);
      
      // Store credentials in local storage for persistence
      localStorage.setItem('client_id', app.client_id);
      localStorage.setItem('client_secret', app.client_secret);
      
      // Generate and store OAuth state
      const state = Math.random().toString(36).substring(2);
      sessionStorage.setItem('oauth_state', state);
      sessionStorage.setItem('mastodon_domain', cleanDomain);

      // Store the current theme to maintain consistency
      const currentTheme = localStorage.getItem('theme-storage');
      if (currentTheme) {
        sessionStorage.setItem('theme-storage', currentTheme);
      }

      // Redirect to Mastodon authorization page
      const authUrl = new URL(`https://${cleanDomain}/oauth/authorize`);
      authUrl.searchParams.append('client_id', app.client_id);
      authUrl.searchParams.append('redirect_uri', getRedirectUri());
      authUrl.searchParams.append('response_type', 'code');
      authUrl.searchParams.append('scope', 'read write follow');
      authUrl.searchParams.append('state', state);

      window.location.href = authUrl.toString();
    } catch (err) {
      setError('Failed to connect to Mastodon instance. Please check the domain and try again.');
      console.error('Login error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={`min-h-screen flex items-center justify-center p-4 ${isDark ? 'dark bg-gray-900' : 'bg-gray-100'}`}>
      <div className="w-full max-w-md p-8 bg-white dark:bg-gray-800 rounded-lg shadow-lg relative">
        <div className="absolute top-4 right-4 z-10">
          <ThemeToggle />
        </div>
        <div className="flex flex-col items-center justify-center mb-8">
          <Globe className="w-12 h-12 text-blue-500" />
          <h2 className="text-2xl font-bold text-center mt-4 mb-2 text-gray-900 dark:text-white">
            Connect to Mastodon
          </h2>
          <p className="text-sm text-gray-600 dark:text-gray-400 text-center">
            Enter your Mastodon instance domain to get started
          </p>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="domain" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Instance Domain
            </label>
            <input
              ref={inputRef}
              type="text"
              id="domain"
              value={domain}
              onChange={(e) => setDomain(e.target.value)}
              placeholder="mastodon.social"
              className={`mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md text-gray-900 dark:text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${
                isLoading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              required
              disabled={isLoading}
              autoFocus
            />
          </div>
          {error && (
            <div className="p-3 bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-800 rounded-md">
              <p className="text-red-600 dark:text-red-400 text-sm break-words">{error}</p>
            </div>
          )}
          <button
            type="submit"
            disabled={isLoading}
            className={`w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed dark:focus:ring-offset-gray-800 ${
              isLoading ? 'cursor-not-allowed' : ''
            }`}
          >
            {isLoading ? (
              <div className="flex items-center space-x-2">
                <LoadingSpinner size="sm" className="border-white" />
                <span>Connecting...</span>
              </div>
            ) : (
              'Connect'
            )}
          </button>
        </form>
        {isLoading && (
          <div className="absolute inset-0 bg-white/50 dark:bg-gray-800/50 flex items-center justify-center backdrop-blur-sm rounded-lg z-0">
            <div className="text-center">
              <LoadingSpinner size="lg" className="mx-auto mb-4" />
              <p className="text-gray-600 dark:text-gray-400 animate-pulse">
                Connecting to Mastodon...
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}