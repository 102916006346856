import type { Message } from '../../../types/mastodon';
import { cleanMessageContent } from '../../../utils/message/formatting';
import { proxyRequest } from '../proxyApi';

export async function fetchConversationMessages(
  domain: string,
  accessToken: string,
  conversationId: string
): Promise<Message[]> {
  const response = await proxyRequest<any[]>({
    url: `https://${domain}/api/v1/conversations/${conversationId}/messages`,
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` }
  });

  return response.map(msg => ({
    id: msg.id,
    content: cleanMessageContent(msg.content),
    sender: msg.account.acct,
    timestamp: new Date(msg.created_at),
    conversationId,
    senderInfo: {
      id: msg.account.id,
      username: msg.account.username,
      displayName: msg.account.display_name || msg.account.username,
      avatar: msg.account.avatar,
      acct: msg.account.acct
    }
  }));
}

export async function sendDirectMessage(
  domain: string,
  accessToken: string,
  conversationId: string,
  content: string
) {
  const response = await proxyRequest({
    url: `https://${domain}/api/v1/conversations/${conversationId}/messages`,
    method: 'POST',
    data: { content },
    headers: { Authorization: `Bearer ${accessToken}` }
  });

  return response;
}

export async function fetchMessages(
  domain: string,
  accessToken: string,
  conversationId: string
): Promise<Message[]> {
  const response = await proxyRequest<any[]>({
    url: `https://${domain}/api/v1/conversations/${conversationId}/messages`,
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` }
  });

  return response.map(msg => ({
    id: msg.id,
    content: cleanMessageContent(msg.content),
    sender: msg.account.acct,
    timestamp: new Date(msg.created_at),
    conversationId,
    senderInfo: {
      id: msg.account.id,
      username: msg.account.username,
      displayName: msg.account.display_name || msg.account.username,
      avatar: msg.account.avatar,
      acct: msg.account.acct
    }
  }));
}