import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Message, MastodonInstance, Conversation, User } from '../types/mastodon';
import { ensureDate } from '../utils/date/comparison';
import { cleanupAuth } from '../services/supabase';

interface MastodonStore {
  instance: MastodonInstance | null;
  currentUser: User | null;
  conversations: Conversation[];
  messages: Record<string, Message[]>;
  activeConversation: string | null;
  setInstance: (instance: MastodonInstance | null) => void;
  setCurrentUser: (user: User | null) => void;
  setConversations: (conversations: Conversation[]) => void;
  addConversation: (conversation: Conversation) => void;
  setMessages: (conversationId: string, messages: Message[]) => void;
  addMessage: (message: Message) => void;
  setActiveConversation: (conversationId: string | null) => void;
  clearStore: () => Promise<void>;
}

export const useMastodonStore = create<MastodonStore>()(
  persist(
    (set) => ({
      instance: null,
      currentUser: null,
      conversations: [],
      messages: {},
      activeConversation: null,
      setInstance: (instance) => set({ instance }),
      setCurrentUser: (user) => set({ currentUser: user }),
      setConversations: (conversations) => set({ conversations }),
      addConversation: (conversation) => set((state) => ({
        conversations: state.conversations.some(c => c.id === conversation.id)
          ? state.conversations.map(c => c.id === conversation.id ? { ...c, ...conversation } : c)
          : [...state.conversations, conversation]
      })),
      setMessages: (conversationId, messages) => set((state) => ({
        messages: {
          ...state.messages,
          [conversationId]: messages.map(msg => ({
            ...msg,
            timestamp: ensureDate(msg.timestamp)
          }))
        }
      })),
      addMessage: (message) => set((state) => ({
        messages: {
          ...state.messages,
          [message.conversationId]: [
            ...(state.messages[message.conversationId] || []),
            { ...message, timestamp: ensureDate(message.timestamp) }
          ].sort((a, b) => ensureDate(a.timestamp).getTime() - ensureDate(b.timestamp).getTime())
        }
      })),
      setActiveConversation: (conversationId) => set({ activeConversation: conversationId }),
      clearStore: async () => {
        try {
          // Sign out from Supabase
          await cleanupAuth();
          
          // Then clear the store
          set({
            instance: null,
            currentUser: null,
            conversations: [],
            messages: {},
            activeConversation: null
          });
          
          console.log('Messagedon: Store cleared successfully');
        } catch (error) {
          console.error('Store cleanup failed:', error);
          throw error;
        }
      }
    }),
    {
      name: 'mastodon-store',
      partialize: (state) => ({
        instance: state.instance,
        currentUser: state.currentUser
      })
    }
  )
);