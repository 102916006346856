import axios from 'axios';
import { UserInfo } from '../../types/mastodon';

export async function createMastodonApp(domain: string) {
  try {
    const response = await axios.post(`https://${domain}/api/v1/apps`, {
      client_name: 'Messagedon',
      redirect_uris: window.location.origin + '/oauth/callback',
      scopes: 'read write follow',
      website: window.location.origin
    });
    return response.data;
  } catch (error) {
    console.error('Error creating Mastodon app:', error);
    throw error;
  }
}

export async function searchUsers(domain: string, accessToken: string, query: string): Promise<UserInfo[]> {
  try {
    const response = await axios.get(
      `https://${domain}/api/v1/accounts/search`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
        params: { q: query, limit: 10 }
      }
    );
    return response.data.map((user: any) => ({
      id: user.id,
      username: user.username,
      displayName: user.display_name || user.username,
      avatar: user.avatar,
      acct: user.acct
    }));
  } catch (error) {
    console.error('Error searching users:', error);
    return [];
  }
}

export async function getUserByHandle(domain: string, accessToken: string, handle: string): Promise<UserInfo | null> {
  try {
    const [username, userDomain] = handle.split('@').filter(Boolean);
    const response = await axios.get(
      `https://${domain}/api/v1/accounts/lookup`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
        params: { acct: userDomain ? `${username}@${userDomain}` : username }
      }
    );
    return {
      id: response.data.id,
      username: response.data.username,
      displayName: response.data.display_name || response.data.username,
      avatar: response.data.avatar,
      acct: response.data.acct
    };
  } catch (error) {
    console.error('Error looking up user:', error);
    return null;
  }
}

export async function getAccessToken(domain: string, code: string, clientId: string, clientSecret: string): Promise<string> {
  try {
    const response = await axios.post(`https://${domain}/oauth/token`, {
      client_id: clientId,
      client_secret: clientSecret,
      redirect_uri: window.location.origin + '/oauth/callback',
      grant_type: 'authorization_code',
      code: code,
      scope: 'read write follow'
    });
    return response.data.access_token;
  } catch (error) {
    console.error('Error getting access token:', error);
    throw error;
  }
}

export async function verifyCredentials(domain: string, accessToken: string): Promise<UserInfo> {
  try {
    const response = await axios.get(`https://${domain}/api/v1/accounts/verify_credentials`, {
      headers: { Authorization: `Bearer ${accessToken}` }
    });
    
    return {
      id: response.data.id,
      username: response.data.username,
      displayName: response.data.display_name || response.data.username,
      avatar: response.data.avatar,
      acct: response.data.acct
    };
  } catch (error) {
    console.error('Error verifying credentials:', error);
    throw error;
  }
}