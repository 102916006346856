import { supabase } from '../client';
import type { Conversation } from '../../../types/mastodon';
import type { RealtimeChannel } from '@supabase/supabase-js';

const MAX_RETRIES = 3;
const RETRY_DELAY = 2000;

export function subscribeToConversations(
  instanceDomain: string,
  onUpdate: (conversation: Conversation) => void
): RealtimeChannel {
  const channelId = `conversations:${instanceDomain.replace(/[^a-zA-Z0-9-]/g, '_')}`;
  let retryCount = 0;

  const channel = supabase
    .channel(channelId)
    .on(
      'postgres_changes',
      {
        event: '*',
        schema: 'public',
        table: 'conversations',
        filter: `instance_domain=eq."${instanceDomain}"`
      },
      (payload) => {
        if (payload.eventType === 'DELETE') return;
        
        const conversation = {
          id: payload.new.id,
          participants: payload.new.participants,
          lastMessage: payload.new.last_message,
          unread: payload.new.unread
        };
        onUpdate(conversation);
      }
    );

  const handleSubscribe = async () => {
    try {
      await channel.subscribe((status) => {
        if (status === 'SUBSCRIBED') {
          console.log('Successfully subscribed to conversations');
          retryCount = 0;
        } else if (status === 'CHANNEL_ERROR' && retryCount < MAX_RETRIES) {
          retryCount++;
          console.log(`Retrying subscription (${retryCount}/${MAX_RETRIES})`);
          setTimeout(handleSubscribe, RETRY_DELAY);
        }
      });
    } catch (error) {
      console.error('Subscription error:', error);
      if (retryCount < MAX_RETRIES) {
        retryCount++;
        setTimeout(handleSubscribe, RETRY_DELAY);
      }
    }
  };

  handleSubscribe();
  return channel;
}