import axios from 'axios';
import { nanoid } from 'nanoid';

const PROXY_URL = '/.netlify/functions/proxy';

let cachedToken: string | null = null;
let proxySecret: string | null = null;

export function setProxyToken(token: string) {
  if (!token) return;
  cachedToken = token;
  proxySecret = proxySecret || nanoid(); // Only generate if not exists
}

export function clearProxyToken() {
  cachedToken = null;
  proxySecret = null;
}

export async function proxyRequest<T>({
  url,
  method,
  params,
  data,
  headers = {}
}: {
  url: string;
  method: string;
  params?: Record<string, string>;
  data?: any;
  headers?: Record<string, string>;
}): Promise<T> {
  if (!cachedToken) {
    throw new Error('No access token available');
  }

  try {
    const timestamp = Date.now().toString();
    const response = await axios.post(PROXY_URL, {
      url,
      method,
      params,
      data,
      headers: {
        ...headers,
        'Authorization': `Bearer ${cachedToken}`,
        'X-Proxy-Auth': proxySecret,
        'X-Request-Timestamp': timestamp
      }
    });

    return response.data;
  } catch (error) {
    console.error('Proxy request error:', error);
    throw error;
  }
}