import { supabase } from '../client';
import type { Message } from '../../../types/mastodon';
import type { RealtimeChannel } from '@supabase/supabase-js';

const MAX_RETRIES = 3;
const RETRY_DELAY = 2000;

export function subscribeToMessages(
  conversationId: string,
  instanceDomain: string,
  onMessage: (message: Message) => void
): RealtimeChannel {
  const channelId = `messages:${conversationId}:${instanceDomain.replace(/[^a-zA-Z0-9-]/g, '_')}`;
  let retryCount = 0;

  const channel = supabase
    .channel(channelId)
    .on(
      'postgres_changes',
      {
        event: '*',
        schema: 'public',
        table: 'messages',
        filter: `conversation_id=eq."${conversationId}" and instance_domain=eq."${instanceDomain}"`
      },
      (payload) => {
        if (payload.eventType === 'DELETE') return;
        
        const message = {
          id: payload.new.id,
          content: payload.new.content,
          sender: payload.new.sender,
          timestamp: new Date(payload.new.timestamp),
          conversationId: payload.new.conversation_id,
          senderInfo: payload.new.sender_info
        };
        onMessage(message);
      }
    );

  const handleSubscribe = async () => {
    try {
      await channel.subscribe((status) => {
        if (status === 'SUBSCRIBED') {
          console.log('Successfully subscribed to messages');
          retryCount = 0;
        } else if (status === 'CHANNEL_ERROR' && retryCount < MAX_RETRIES) {
          retryCount++;
          console.log(`Retrying subscription (${retryCount}/${MAX_RETRIES})`);
          setTimeout(handleSubscribe, RETRY_DELAY);
        }
      });
    } catch (error) {
      console.error('Subscription error:', error);
      if (retryCount < MAX_RETRIES) {
        retryCount++;
        setTimeout(handleSubscribe, RETRY_DELAY);
      }
    }
  };

  handleSubscribe();
  return channel;
}