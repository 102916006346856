import { Message } from '../types/mastodon';
import { decode } from 'he';

export interface MessageGroup {
  date: Date;
  messages: Message[];
}

export function ensureDate(date: Date | string): Date {
  return date instanceof Date ? date : new Date(date);
}

export function cleanMessageContent(content: string): string {
  let cleanContent = content.replace(/<[^>]*>/g, '');
  cleanContent = decode(cleanContent);
  cleanContent = cleanContent.replace(/@[\w\-]+(@[\w\-\.]+)?/g, '').trim();
  return cleanContent.replace(/\s+/g, ' ').trim();
}

export function getDomainFromAcct(acct: string): string {
  const parts = acct.split('@');
  return parts.length > 1 ? parts[parts.length - 1] : 'local';
}

export function groupMessagesByDomain(messages: Message[]): Message[] {
  if (!Array.isArray(messages)) return [];
  
  const mergedMessages: Message[] = [];
  let currentDomain = '';
  let lastMessage: Message | null = null;

  const sortedMessages = [...messages].sort((a, b) => 
    ensureDate(a.timestamp).getTime() - ensureDate(b.timestamp).getTime()
  );

  sortedMessages.forEach(message => {
    if (!message.sender) return;
    
    const messageDomain = getDomainFromAcct(message.sender);
    
    if (messageDomain === currentDomain && lastMessage) {
      lastMessage.content += '\n' + message.content;
    } else {
      currentDomain = messageDomain;
      lastMessage = { ...message };
      mergedMessages.push(lastMessage);
    }
  });

  return mergedMessages;
}

export function groupMessagesByTime(messages: Message[]): MessageGroup[] {
  if (!Array.isArray(messages)) return [];
  
  const mergedMessages = groupMessagesByDomain(messages);
  const groups: MessageGroup[] = [];
  
  mergedMessages.forEach(message => {
    const messageDate = ensureDate(message.timestamp);
    messageDate.setHours(0, 0, 0, 0);

    const existingGroup = groups.find(group => 
      group.date.getTime() === messageDate.getTime()
    );

    if (existingGroup) {
      existingGroup.messages.push(message);
    } else {
      groups.push({
        date: messageDate,
        messages: [message]
      });
    }
  });

  groups.sort((a, b) => a.date.getTime() - b.date.getTime());

  groups.forEach(group => {
    group.messages.sort((a, b) => 
      ensureDate(a.timestamp).getTime() - ensureDate(b.timestamp).getTime()
    );
  });

  return groups;
}