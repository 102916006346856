import React from 'react';
import { formatDate } from '../../utils/date/formatting';

interface MessageGroupProps {
  date: Date;
  children: React.ReactNode;
}

export function MessageGroup({ date, children }: MessageGroupProps) {
  return (
    <div className="space-y-2">
      <div className="flex items-center justify-center">
        <div className="text-xs text-gray-500 dark:text-gray-400 bg-white dark:bg-gray-800 px-3 py-1 rounded-full shadow-sm">
          {formatDate(date)}
        </div>
      </div>
      {children}
    </div>
  );
}